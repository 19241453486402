import React, { PropsWithChildren, useEffect } from 'react';
import styles from './Solution.module.scss';
import { PageHeader, Button } from '../../../components';
import Helmet from 'react-helmet';
import { ContactWizard } from '../../../components/contact-wizard/ContactWizard';

import prototypeImage from '../../../assets/solutions/prototype.svg';
import cxImage from '../../../assets/solutions/cx.svg';
import mobileImage from '../../../assets/solutions/mobileapps.svg';
import uxImage from '../../../assets/solutions/usability.svg';
import propsImage from '../../../assets/solutions/valueproposition.svg';
import webImage from '../../../assets/solutions/webapps.svg';
import { useContactForm } from '../../../components/lib/hooks/useContactForm';

function scrollDown(): void {
  const bottom = document.body.querySelector('[data-contact-form]');
  console.log(bottom);
  bottom?.scrollIntoView({ behavior: 'smooth' });
}

function ContactFormPartial(): JSX.Element {
  const { submitForm } = useContactForm();

  return (
    <div className={styles.contactFormWrapper}>
      <ContactWizard onSubmit={value => submitForm(value)} />
    </div>
  );
}

interface WithNavigationProps {
  nextUrl?: string;
  previousUrl?: string;
  nextName?: string;
  previousName?: string;
}

function WithNavigation(
  props: PropsWithChildren<WithNavigationProps>
): JSX.Element {
  useEffect(() => {
    const handler = (event: KeyboardEvent) => {
      if (event.key === 'ArrowLeft') {
        window.location.href = props.previousUrl;
      } else if (event.key === 'ArrowRight') {
        window.location.href = props.nextUrl;
      }
    };
    window.addEventListener('keyup', handler);

    return () => {
      window.removeEventListener('keyup', handler);
    };
  });

  return (
    <div className={styles.pageWithNavigation}>
      <div className={styles.pageNavigation}>
        <a className={styles.previous} href={props.previousUrl}>
          <i />
          <span>{props.previousName ?? 'Previous'}</span>
        </a>
        <a className={styles.next} href={props.nextUrl}>
          <i />
          <span>{props.nextName ?? 'Next'}</span>
        </a>
      </div>

      <div className="pageOutlet">{props.children}</div>

      <div className={styles.allSection}>
        <Button href="/solutions" as="a" isSmall={true} theme="light">
          All Solutions
        </Button>
        {/* <a href="/solutions" className="with-arrow reverse">
          All Solutions
        </a> */}
      </div>
    </div>
  );
}

export function SolutionPrototype(): JSX.Element {
  return (
    <WithNavigation
      previousName="Value Props"
      previousUrl="/solutions/value-propositions"
      nextName="UX Design"
      nextUrl="/solutions/ux-design"
    >
      <Helmet>
        <title>OnePointFour | Product Prototyping</title>
        <meta
          name="description"
          content="Prototypes so real that you’d think it was live already. Get real-time feedback from real users without the cost of developing a full solution."
        />
      </Helmet>
      <section className={`${styles.solutionPage} container`}>
        <PageHeader imageUrl={prototypeImage}>
          <div className={styles.headerInner}>
            <h2 className="display-light">Product</h2>
            <h1 className="display-bold">
              Prototype <i className="dot"></i>
            </h1>
            <p className="display">
              Prototypes so real that you’d think it was live already. Get
              real-time feedback from real users without the cost of developing
              a full solution.
            </p>
            <br />
            <Button theme="blue" onClick={scrollDown}>
              PROTOTYPE YOUR PRODUCT
            </Button>
          </div>
        </PageHeader>

        <div className={styles.information}>
          <h1>
            Confirm <strong>app</strong>etite before you build
          </h1>
          <p>
            We use the latest tools to create website and app prototypes that
            look and feel like the real thing. If you want to test out a
            business hypothesis or confirm whether or not your product resonates
            with the market - we can get you smart intel at roughly 3% of what
            the actual cost would be to develop and build out the full solution.
            Don’t waste time and money building the wrong product, our
            prototypes are ideal for product or feature prioritisation,
            usability testing and market analysis.
          </p>
        </div>

        <ContactFormPartial />
      </section>
    </WithNavigation>
  );
}

export function SolutionWebApplication(): JSX.Element {
  return (
    <WithNavigation
      previousName="UX Design"
      previousUrl="/solutions/ux-design"
      nextName="Mobile Apps"
      nextUrl="/solutions/mobile-applications"
    >
      <Helmet>
        <title>OnePointFour | Web Applications</title>
        <meta
          name="description"
          content="Cloud hosted web apps that don’t compromise on functionality. Continually ship new product features and maintain your competitive advantage."
        />
      </Helmet>
      <section className={`${styles.solutionPage} container`}>
        <PageHeader imageUrl={webImage}>
          <div className={styles.headerInner}>
            <h2 className="display-light">Web</h2>
            <h1 className="display-bold">
              applications <i className="dot"></i>
            </h1>
            <p className="display">
              Cloud hosted web apps that don’t compromise on functionality.
              Continually ship new product features and maintain your
              competitive advantage.
            </p>
            <br />
            <Button theme="blue" onClick={scrollDown}>
              BUILD YOUR WEB APP
            </Button>
          </div>
        </PageHeader>

        <div className={styles.information}>
          <h1>Why app, when you can web app?</h1>
          <p>
            One way to be smart with your money is to build with the right
            technology for your specific product. We can help you develop a
            digital strategy specific to your business. Modern solutions don’t
            need a one-size-fits-all approach. Web applications can do most of
            the work of traditional native apps, but have some serious benefits.
            Our web apps are iOS and Android ready, work on the web and have
            best of breed security and compliance (GDPR & POPIA) built right in.
            Ship once to multiple platforms without having to bear the cost of
            developing and maintaining separate native apps.
          </p>
        </div>

        <ContactFormPartial />
      </section>
    </WithNavigation>
  );
}

export function SolutionValueProps(): JSX.Element {
  return (
    <WithNavigation
      previousName="CX Design"
      previousUrl="/solutions/cx-design"
      nextName="Prototypes"
      nextUrl="/solutions/prototypes"
    >
      <Helmet>
        <title>OnePointFour | Value Propositions</title>
        <meta
          name="description"
          content="You have an idea that’s going to change the world, but how can you be sure? Maximising value and testing out assumptions is where we can help."
        />
      </Helmet>
      <section className={`${styles.solutionPage} container`}>
        <PageHeader imageUrl={propsImage}>
          <div className={styles.headerInner}>
            <h2 className="display-light">Value</h2>
            <h1 className="display-bold">
              Propositions <i className="dot"></i>
            </h1>
            <p className="display">
              You have an idea that’s going to change the world, but how can you
              be sure? Maximising value and testing out assumptions is where we
              can help.
            </p>
            <br />
            <Button theme="blue" onClick={scrollDown}>
              REFINE YOUR PRODUCT
            </Button>
          </div>
        </PageHeader>

        <div className={styles.information}>
          <h1>True value needs to be maximised</h1>
          <p>
            We firmly believe in empirical processes that test out any
            hypotheses in the real world. A value proposition has no legs unless
            there is a market for it to walk into. A value proposition means
            nothing without feedback or the ability to respond to change. That
            is where we can help. Our highest priority, with anything we design
            or build, is to maximise the value it brings to customers and to the
            business. We adhere to stringent research protocols and use the
            latest methods and tools to enable you with confidence in your idea.
          </p>
        </div>

        <ContactFormPartial />
      </section>
    </WithNavigation>
  );
}

export function SolutionUsabilityTests(): JSX.Element {
  return (
    <WithNavigation
      previousName="Prototypes"
      previousUrl="/solutions/prototypes"
      nextName="Web Apps"
      nextUrl="/solutions/web-applications"
    >
      <Helmet>
        <title>OnePointFour | Usability Testing</title>
        <meta
          name="description"
          content="Whether it’s building out a new product or fixing a kink in the payment funnel. We brutally test, monitor and iron out any user issues in your product. Good usability happens when you’ve catered for when things go wrong."
        />
      </Helmet>
      <section className={`${styles.solutionPage} container`}>
        <PageHeader imageUrl={uxImage}>
          <div className={styles.headerInner}>
            <h2 className="display-light">Usability</h2>
            <h1 className="display-bold">
              testing <i className="dot"></i>
            </h1>
            <p className="display">
              Whether it’s building out a new product or fixing a kink in the
              payment funnel. We brutally test, monitor and iron out any user
              issues in your product. Good usability happens when you’ve catered
              for when things go wrong.
            </p>
            <br />
            <Button theme="blue" onClick={scrollDown}>
              TEST YOUR ASSUMPTIONS
            </Button>
          </div>
        </PageHeader>

        <div className={styles.information}>
          <h1>Good tech is invisible</h1>
          <p>
            Much like the soundtrack in a movie, you are never aware of it
            unless it is bad. The interface and the background tech should be
            invisible to the user. Usability is the difference between making a
            customer conversion and losing business. We are experts at creating
            interactions that are simple, intuitive and empirically provable.
            Our methods range from the qualitative to quantitative, but the aim
            is always to iteratively improve and measure the success of your
            product. Unlike many other companies our methods are based on good
            statistical use and understanding. We also use real-world people
            within your intended market to do testing - the results (and the
            users) speak for themselves.
          </p>
        </div>

        <ContactFormPartial />
      </section>
    </WithNavigation>
  );
}

export function SolutionMobileApplications(): JSX.Element {
  return (
    <WithNavigation
      previousName="Web Apps"
      previousUrl="/solutions/web-applications"
      nextName="CX Design"
      nextUrl="/solutions/cx-design"
    >
      <Helmet>
        <title>OnePointFour | Mobile Applications</title>
        <meta
          name="description"
          content="Struggling with the decision to go with a hybrid or native app? We can help define the best strategy for your product. We develop our apps to the highest standards and have won awards for it."
        />
      </Helmet>
      <section className={`${styles.solutionPage} container`}>
        <PageHeader imageUrl={mobileImage}>
          <div className={styles.headerInner}>
            <h2 className="display-light">Mobile</h2>
            <h1 className="display-bold">
              applications <i className="dot"></i>
            </h1>
            <p className="display">
              Struggling with the decision to go with a hybrid or native app? We
              can help define the best strategy for your product. We develop our
              apps to the highest standards and have won awards for it.
            </p>
            <br />
            <Button theme="blue" onClick={scrollDown}>
              BUILD A MOBILE APP
            </Button>
          </div>
        </PageHeader>

        <div className={styles.information}>
          <h1>An app in the hand... is worth?</h1>
          <p>
            Just having an app is no longer a competitive advantage. Building a
            product that users value and that simultaneously hits key business
            metrics is a trickier task. We help you define a strategy to build
            performant apps that look good on the bottom line. We’ll help you
            build a bespoke product vision that aligns to strategy and kicks any
            competitors in the pants. That is the power of a well-built mobile
            app; instant accessibility and solving customer / business needs.
            All you need is the right partner to help you make it real.
          </p>
        </div>

        <ContactFormPartial />
      </section>
    </WithNavigation>
  );
}

export function SolutionCXServiceDesign(): JSX.Element {
  return (
    <WithNavigation
      previousName="Mobile Apps"
      previousUrl="/solutions/mobile-applications"
      nextName="Value Props"
      nextUrl="/solutions/value-propositions"
    >
      <Helmet>
        <title>OnePointFour | CX & Service Design</title>
        <meta
          name="description"
          content="Digital products don’t exist in isolation, but as a continuation of a customer’s real-world interaction with your brand. Optimise every possible interaction with your customers, online and off."
        />
      </Helmet>
      <section className={`${styles.solutionPage} container`}>
        <PageHeader imageUrl={cxImage}>
          <div className={styles.headerInner}>
            <h2 className="display-light">Customer service</h2>
            <h1 className={`display-bold ${styles.secondLine}`}>
              <span className={styles.light}>&</span> experience{' '}
              <i className="dot"></i>
            </h1>
            <p className="display">
              Digital products don’t exist in isolation, but as a continuation
              of a customer’s real-world interaction with your brand. Optimise
              every possible interaction with your customers, online and off.
            </p>
            <br />
            <Button theme="blue" onClick={scrollDown}>
              CREATE AN EXPERIENCE
            </Button>
          </div>
        </PageHeader>

        <div className={styles.information}>
          <h1>What the heck is CX anyway?</h1>
          <p>
            The joking definition of CX is ‘when you go to see your ex’... The
            thing is, that is not far off from the truth. Customers today are
            very sensitive to a bad brand experience. Let just one thing go
            wrong and social media will hear all about it. For us, customer
            experience is not only about building the best possible experience
            at every touchpoint, but also building in channels and methods to
            manage the inevitable glitch in the matrix.
            <br />
            <br />
            Customer experience is defined by error handling - graceful
            management of a complaint can turn a dissatisfied customer into a
            loyal one. Don’t over-focus on the success case, rather become
            bullet-proof by ensuring you manage system, user and business
            failures.
          </p>
        </div>

        <ContactFormPartial />
      </section>
    </WithNavigation>
  );
}
