import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import styles from './Navbar.module.scss';
import { Button } from '../button/Button';
import logo from '../../assets/logo.grey.svg';
import { useDataLayer } from '../lib/hooks/useDataLayer';

export function Navbar(): JSX.Element {
  const [isScrolled, setIsScrolled] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  const [isClosing, setIsClosing] = useState(false);

  const { pathname } = useLocation();

  const { push } = useDataLayer();

  useEffect(() => {
    const action = () => {
      const y = window.scrollY;
      setIsScrolled(y >= 100);
    };

    window.addEventListener('scroll', action);

    return () => {
      window.removeEventListener('scroll', action);
    };
  });

  function toggleMenu(): void {
    if (!isOpen) {
      setIsOpen(true);
    } else closeMenu();
  }

  function closeMenu(): void {
    setIsClosing(true);
    setTimeout(() => {
      setIsOpen(false);
      setIsClosing(false);
    }, 300);
  }

  return (
    <nav className={`${styles.navbar} ${isScrolled ? styles.isScrolled : ''}`}>
      <div className={`${styles.innerWrapper}`}>
        <a href="/" className={styles.navLogo}>
          <img loading="lazy" src={logo} alt="OnePointFour" />
        </a>
        <ul
          className={`${styles.navLinksWrapper} ${
            isOpen && styles.menuIsOpen
          } ${isClosing && styles.menuIsClosing}`}
        >
          <li onClick={() => closeMenu()}>
            <NavLink exact activeClassName={styles.linkActive} to="/">
              Home
            </NavLink>
          </li>
          {/* <li onClick={() => closeMenu()}>
            <NavLink activeClassName={styles.linkActive} to="/work">
              Work
            </NavLink>
          </li> */}
          <li onClick={() => closeMenu()}>
            <NavLink activeClassName={styles.linkActive} to="/insights">
              Insights
            </NavLink>
          </li>
          <li onClick={() => closeMenu()}>
            <NavLink activeClassName={styles.linkActive} to="/solutions">
              Solutions
            </NavLink>
          </li>
          <li onClick={() => closeMenu()}>
            <NavLink activeClassName={styles.linkActive} to="/about">
              About
            </NavLink>
          </li>
          <li onClick={() => closeMenu()}>
            <NavLink
              activeClassName={styles.linkActive}
              to="/contact"
              onClick={() =>
                push({
                  event: 'CONTACT_US',
                  fromPage: pathname,
                  toPage: '/contact',
                })
              }
            >
              Contact
            </NavLink>
          </li>
        </ul>
        <div className={styles.navCollapseButton}>
          {pathname.includes('contact') ? (
            <Button isSmall={true} as="a" href="/solutions">
              OUR SOLUTIONS
            </Button>
            // <Button isSmall={true} as="a" href="/work">
            //   OUR WORK
            // </Button>
          ) : (
            <Button
              isSmall={true}
              as="a"
              href="/contact"
              onClick={() =>
                push({
                  event: 'CONTACT_US',
                  fromPage: pathname,
                  toPage: '/contact',
                })
              }
            >
              CONTACT US
            </Button>
          )}
        </div>
        <div
          className={`${styles.navMobileMenu} ${
            isOpen && styles.buttonIsActive
          }`}
          onClick={toggleMenu}
        >
          <button>
            <span></span>
            <span></span>
            <span></span>
          </button>
        </div>
      </div>
    </nav>
  );
}
