import { ContactLead } from '../../../models/contact-leads';
import { configs } from '../../../env';

export function useContactForm() {
  return {
    async submitForm(value: ContactLead) {
      await sendForm(value);
      return value;
    },
  };
}

function sendForm(lead: ContactLead): Promise<void> {
  console.log(JSON.stringify(lead, null, 2));

  const http: XMLHttpRequest = new XMLHttpRequest();

  return new Promise((resolve, reject) => {
    http.onload = () => resolve();
    http.onerror = () => reject();

    http.open('POST', `${configs.baseApiUrl}/ContactLeads`);
    http.setRequestHeader('Content-Type', 'application/json');
    http.send(JSON.stringify(lead));
  });
}
