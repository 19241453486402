import React, { useRef, useEffect } from 'react';
import styles from './CaseStudy.module.scss';
import { useParams } from 'react-router-dom';
import { ArticleHeader, CaseStudyGallery } from '../../../components';
import { InformationPanel } from '../../../components/information-panel/InformationPanel';
import useFetch from 'react-fetch-hook';
import { configs } from '../../../env';
import { CaseStudy as CS } from '../../../models/case-study';
import Helmet from 'react-helmet';
import { useDataLayer } from '../../../components/lib/hooks/useDataLayer';

export function CaseStudy(): JSX.Element {
  const { slug } = useParams();

  const ref = useRef<HTMLDivElement>();
  const { push } = useDataLayer();

  const { data } = useFetch<CS>(`${configs.baseApiUrl}/CaseStudies/${slug}`);

  useEffect(() => {
    if (ref.current) {
      const observer = new IntersectionObserver(entries => {
        for (const entry of entries) {
          if (entry.isIntersecting) {
            push({
              event: 'CASE_STUDY_READ',
              eventRef: `${data?.slug} | ${data?.id}`,
            });
            observer.disconnect();
          }
        }
      });

      observer.observe(ref.current);
    }
  });

  if (!data) {
    return (
      <div className={styles.articleLoader}>
        <div className={styles.loader}>
          <div className={styles.logo}>
            <span></span>
            <span className={styles.dot}></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div className={styles.label}>Loading The Study</div>
        </div>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>OnePointFour | {data.title}</title>
        <meta name="title" content={`OnePointFour | ${data.title}`} />
        <meta name="description" content={data.snippet} />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://onepointfour.io/" />
        <meta property="og:title" content={`OnePointFour | ${data.title}`} />
        <meta property="og:description" content={data.snippet} />
        <meta property="og:image" content={data.thumbnailImage} />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://onepointfour.io/" />
        <meta
          property="twitter:title"
          content={`OnePointFour | ${data.title}`}
        />
        <meta property="twitter:description" content={data.snippet} />
        <meta property="twitter:image" content={data.thumbnailImage} />
      </Helmet>
      <h1 style={{ display: 'none' }}>{data.title}</h1>
      <section className={styles.caseStudyPage}>
        <ArticleHeader imageUrl={data.heroImage} />

        <div className={styles.articleWrapper}>
          <article>
            <div className={styles.contentBlockHeader}>
              <h3 className="display-light">{data.tagline}</h3>
              <h2 className={(slug ?? 'no-slug-given') + ' display-bold'}>
                {data.title}
              </h2>
            </div>

            <br />

            <div
              className={styles.contentBlock}
              dangerouslySetInnerHTML={{ __html: data.htmlContent }}
            ></div>

            <div ref={ref} className="scroll-check"></div>

            {!!data.gallery?.length && (
              <div className={styles.contentBlockArticles}>
                <CaseStudyGallery images={data.gallery} />
              </div>
            )}
          </article>

          <nav>
            <InformationPanel
              company={data.forCompany}
              year={data.year}
              included={data.tags?.join(', ')}
              play={data.googlePlayLink}
              app={data.appStoreLink}
              website={data.websiteLink}
              slug={data.slug}
              id={data.id}
            />
          </nav>
        </div>
      </section>
    </>
  );
}
