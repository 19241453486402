import React, { useState, useEffect } from 'react';
import styles from './Insights.module.scss';
import { Helmet } from 'react-helmet';
import { TextHeader, MasonryGrid } from '../../components';
import useFetch from 'react-fetch-hook';
import { configs } from '../../env';
import createTrigger from 'react-use-trigger';
import useTrigger from 'react-use-trigger/useTrigger';
import { Article } from '../../models/article';

const requestTrigger = createTrigger();

let index = 0;

export function Insights(): JSX.Element {
  const trigger = useTrigger(requestTrigger);
  const [articles, setArticles] = useState<any>([]);
  const [showButton, setShowButton] = useState(true);

  const { data } = useFetch<any>(
    `${configs.baseApiUrl}/Articles?pageSize=6&pageNumber=${index}&sortField=publishDate&sortDescending=true`,
    {
      depends: [trigger],
    }
  );

  useEffect(() => {
    if (!!data?.length) {
      setArticles([
        ...articles,
        data.map((art: Article) => {
          if (art.snippet.length > 100) {
            art.snippet = art.snippet.substring(0, 100).trim() + '...';
          }

          return art;
        }),
      ]);
    } else if (Array.isArray(data) && data.length === 0) {
      setShowButton(false);
    }
    // eslint-disable-next-line
  }, [data]);

  if (!data) {
    return (
      <div className={styles.articleLoader}>
        <div className={styles.loader}>
          <div className={styles.logo}>
            <span></span>
            <span className={styles.dot}></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div className={styles.label}>
            Serving Up Some Piping Hot Insights
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={`page ${styles.insightsPage} margin-inline-auto`}>
      <Helmet>
        <title>OnePointFour | Our Insights</title>
        <meta
          name="description"
          content="Read articles from our awesome team on topics that they have been exploring"
        />
      </Helmet>
      <TextHeader
        lineOne="Our"
        lineTwo="insights"
        description="Get a taste of what our team is thinking and talking about."
      ></TextHeader>
      {data && (
        <article className="padding-block-end-6">
          <MasonryGrid
            onNeedsMore={() => {
              index++;
              requestTrigger();
            }}
            showButton={showButton}
            rows={articles}
          />
        </article>
      )}
    </div>
  );
}
