import React from 'react';
import styles from './Work.module.scss';
import { Helmet } from 'react-helmet';
import {
  TextHeader,
  MediumArticleCard,
  LargeArticleCard,
} from '../../components';
import { useFeaturedCaseStudy } from '../../components/lib/hooks/useFeaturedCaseStudy';
import useFetch from 'react-fetch-hook';
import { CaseStudy } from '../../models/case-study';
import { configs } from '../../env';

export function Work(): JSX.Element {
  const { featured } = useFeaturedCaseStudy();

  const { data } = useFetch<CaseStudy[]>(
    `${configs.baseApiUrl}/CaseStudies?sortField=year&sortDescending=true`
  );

  if (!featured) {
    return (
      <div className={styles.articleLoader}>
        <div className={styles.loader}>
          <div className={styles.logo}>
            <span></span>
            <span className={styles.dot}></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div className={styles.label}>Getting the Case Studies</div>
        </div>
      </div>
    );
  }

  return (
    <div className={`page ${styles.workPage} margin-inline-auto`}>
      <Helmet>
        <title>OnePointFour | Work portfolio</title>
        <meta
          name="description"
          content="We know what it takes to ship smart digital products. And we know how to deliver CX-driven solutions that really work."
        />
      </Helmet>

      <div className={styles.paddingMobile}></div>

      <TextHeader
        lineOne="Our"
        lineTwo="Work"
        description="We know what it takes to ship smart digital products. And we know how to deliver CX-driven solutions that really work. But don’t just take our word for it - take a look for yourself."
      />

      {!!featured && (
        <article className="padding-block-end-3 margin-block-end-6">
          <LargeArticleCard
            image={featured.featuredImage}
            title={featured.title}
            description={featured.snippet}
            url={`/work/case-study/${featured.slug}`}
            buttonText={featured.buttonText ?? 'Read More'}
          />
        </article>
      )}

      {!!data?.length && (
        <article className="padding-block-6">
          <h2 className="h1 margin-block-end-6">Case Studies</h2>

          {data.map((article, i) => {
            return (
              <div
                className="padding-inline-2 margin-block-end-6 padding-block-end-4"
                key={i}
              >
                <MediumArticleCard
                  image={article.thumbnailImage}
                  title={article.title}
                  description={article.snippet.substring(0, 300) + '...'}
                  url={`/work/case-study/${article.slug}`}
                  year={article.year}
                  tags={article.tags}
                  isFeatured={article.isFeatured}
                  buttonText={article.buttonText ?? 'Read More'}
                />
              </div>
            );
          })}
        </article>
      )}
    </div>
  );
}
