import useFetch from 'react-fetch-hook';
import { configs } from '../../../env';
import { Employee } from '../../../models/employee';

export function useEmployees() {
  const { data, isLoading } = useFetch<Employee[]>(
    `${configs.baseApiUrl}/Employees?sortDescending=true&sortField=displayOrder`
  );
  return { isLoading, data: data || [] };
}
