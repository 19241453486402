import React from 'react';
import { NavLink } from 'react-router-dom';
import styles from './Footer.module.scss';
import logo from '../../assets/logo.svg';
import pkg from '../../../package.json';
import { addConfetti } from '../lib/confetti/confetti';

export function Footer(): JSX.Element {
  return (
    <footer className={`${styles.footer}`}>
      <div className={`${styles.footerLinks} padding-block-6`}>
        <div className="container">
          <div
            className={`${styles.footerLogo} margin-inline-auto margin-block-end-3`}
          >
            <img
              title={`${process.env.BRANCH} | ${pkg.version}`}
              src={logo}
              alt="OnePointFour Logo"
            />
          </div>

          <div
            className={`${styles.footerSocial} margin-block-end-3 padding-block-end-2`}
          >
            <ul>
              <li className={styles.socialFacebook}>
                <a
                  href="https://www.facebook.com/OnePointFourConsulting/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg fill="none" viewBox="0 0 8 16">
                    <path
                      fill="#fff"
                      fillRule="evenodd"
                      d="M1.81513 5.23076H0v2.73499h1.81513V16h3.49045V7.93157h2.43518L8 5.23076H5.30558V3.69231c0-.63681.13892-.88893.80554-.88893h1.88884V0H5.58334C2.9861 0 1.81513 1.0555 1.81513 3.07693v2.15383z"
                      clipRule="evenodd"
                    />
                  </svg>
                </a>
              </li>

              <li className={`${styles.socialTwitter} margin-inline-4`}>
                <a
                  href="https://twitter.com/1point4sa"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg fill="none" viewBox="0 0 21 16">
                    <path
                      fill="#fff"
                      fillRule="evenodd"
                      d="M20.3027 1.89396c-.7358.32132-1.5266.53851-2.3566.63604.847-.49989 1.4978-1.29143 1.8041-2.234681-.7929.462906-1.671.799031-2.6057.980141C16.3961.490431 15.3297 0 14.1495 0c-2.6486 0-4.59555 2.43309-3.997 4.9596C6.74235 4.7912 3.71885 3.18305 1.69511.739397.620259 2.55446 1.13759 4.92983 2.96492 6.13208c-.67253-.02093-1.30539-.20266-1.8585-.50529C1.06191 7.4982 2.42419 9.24808 4.39744 9.6384c-.57747.15469-1.21.1901-1.85291.06915.52204 1.60465 2.03748 2.77245 3.83301 2.80505-1.7251 1.3308-3.89782 1.9252-6.074806 1.6726C2.11856 15.3312 4.2754 16 6.59259 16 14.2106 16 18.5147 9.66588 18.2553 3.98485c.8018-.56947 1.4973-1.28086 2.0474-2.09089z"
                      clipRule="evenodd"
                    />
                  </svg>
                </a>
              </li>

              <li className={styles.socialLinkdin}>
                <a
                  href="https://www.linkedin.com/company/onepointfour-consulting/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg fill="none" viewBox="0 0 17 16">
                    <path
                      fill="#fff"
                      fillRule="evenodd"
                      d="M.604492 1.89415c0-.52857.183927-.966065.551788-1.312495.36786-.346431.84821-.519643 1.44107-.519643s1.06785.173212 1.425.519643c.35714.34643.53929.783925.54643 1.312495.00714.52144-.17321.95715-.54107 1.30715-.36786.35-.85179.525-1.45179.525h-.02143c-.58571 0-1.05714-.175-1.41428-.525-.357148-.35-.535718-.78571-.535718-1.30715zM.808594 15.7897V5.17188H4.34431V15.7897H.808594zm5.128906.2099c.01429-2.85 0-5.6952 0-7.46668v-3.2l3.88095-.16071v1.54286c.12143-.22858.26785-.42857.41785-.6.15-.17143.3518-.35714.6054-.55715.2535-.2.5643-.35535.9321-.46607.3679-.11071.7768-.16607 1.2268-.16607 1.2214 0 2.2036.40535 2.9464 1.21607.7429.81072.6572 1.89345.6572 3.45774v6.40001h-3.2l.1321-5.8881c0-.75001-.1446-1.3375-.4339-1.7625-.2893-.42501-.7411-.6375-1.3554-.6375-.45 0-.8268.12321-1.1303.36964-.3036.24643-.79587 1.15416-.94587 1.51845-.07857.21429 0 6.40001 0 6.40001H5.9375z"
                      clipRule="evenodd"
                    />
                  </svg>
                </a>
              </li>
            </ul>
          </div>

          <div className={`${styles.footerSitemap}`}>
            <ul>
              <li className="padding-inline-2">
                <NavLink to="/">Home</NavLink>
              </li>

              <li className="padding-inline-2">
                <NavLink to="/work">Work</NavLink>
              </li>

              <li className="padding-inline-2">
                <NavLink to="/">Insights</NavLink>
              </li>

              <li className="padding-inline-2">
                <NavLink to="/solutions">Solutions</NavLink>
              </li>

              <li className="padding-inline-2">
                <NavLink to="/about">About</NavLink>
              </li>

              <li className="padding-inline-2">
                <NavLink to="/contact">Contact</NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className={`${styles.footerLegal} padding-block-4`}>
        <div className={`container ${styles.footerLegalInner}`}>
          <div className={`${styles.footerCopyright}`}>
            &copy;{new Date().getFullYear()} OnePointFour Consulting (Pty) LTD.
            All rights reserved.
          </div>

          <div className={`${styles.footerCopyrightLinks}`}>
            <ul>
              <li onMouseEnter={addConfetti}>
                <NavLink to="/legal/terms-and-conditions">Terms of Use</NavLink>
              </li>

              <li onMouseEnter={addConfetti}>
                <NavLink to="/legal/privacy-policy">Privacy policy</NavLink>
              </li>

              <li onMouseEnter={addConfetti}>
                <NavLink to="/legal/cookie-policy">Cookie policy</NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}
