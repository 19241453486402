import React from 'react';
import styles from './PhotoGrid.module.scss';
import { Employee } from '../../models/employee';
import { LazyLoader } from '../../components';

interface PhotoGridProps {
  items: Array<[Employee, Employee, Employee]>;
}

export function PhotoGrid(props: PhotoGridProps): JSX.Element {
  return (
    <div className={styles.photoGridWrapper}>
      {!!props.items?.length &&
        props.items.map((item, i) => {
          return (
            <div className={styles.photoGridRow} key={i}>
              {item?.length &&
                item.map((img, ii) => {
                  return (
                    <div className={styles.photoItem} key={i + '' + ii}>
                      <img
                        loading="lazy"
                        src={img.profileImage}
                        alt={img.firstName}
                      />
                      <img
                        loading="eager"
                        src={img.hoverProfileImage}
                        alt={img.firstName}
                      />

                      <div className={styles.info}>
                        <h3>
                          {img.firstName} {img.surname}
                        </h3>
                        <h5 className="greyed margin-block-end-1">
                          {img.role}
                        </h5>
                        <ul className={`${styles.social}`}>
                          {img.socialMedia.map((media, im) => {
                            return (
                              <li key={i + '' + ii + '' + im}>
                                <a
                                  href={media.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  dangerouslySetInnerHTML={{
                                    __html: media.icon,
                                  }}
                                ></a>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  );
                })}
            </div>
          );
        })}

      {!props.items?.length && (
        <div className={styles.loadingPage}>
          <LazyLoader forceShow={true} forceType="a" />
        </div>
      )}
    </div>
  );
}
