import React, { useState } from 'react';
import styles from './About.module.scss';
import Helmet from 'react-helmet';
import desktopHeader from '../../assets/AboutUs_desktop.svg';
import tabletHeader from '../../assets/AboutUs_tablet.svg';
import mobileHeader from '../../assets/AboutUs_mobile.svg';
import { PhotoGrid, ScrollDownArrow, Pong, Button } from '../../components';
import { useMediaQuery } from '../../components/lib/utils/useMediaQuery';
import { useEmployees } from '../../components/lib/hooks/useEmployees';
import { chunk } from '../../components/lib/utils/chunk';

export function About(): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);

  const { data } = useEmployees();

  const matches = useMediaQuery('screen and (pointer: coarse)');

  const tablet = useMediaQuery('screen and (max-width: 1050px)');
  const mobile = useMediaQuery('screen and (max-width: 664px)');

  async function handleFullscreen(element: HTMLElement): Promise<void> {
    if (matches) return;

    element.onfullscreenchange = () => {
      setIsOpen(document.fullscreenElement !== null);
    };

    await element.requestFullscreen({ navigationUI: 'hide' });
  }

  function startGame() {
    const target = document.querySelector(`.${styles.aboutUsSnippet}`);
    handleFullscreen(target as any);
  }

  return (
    <div className={`${styles.about} page margin-inline-auto`}>
      <Helmet>
        <title>OnePointFour | What we’re all about</title>
        <meta
          name="description"
          content="We’re CX-led software consultants who believe that nothing and no-one should be safe from scrutiny. Truly smart solutions don’t come from relying on what’s been done before. Neither does a culture of continual improvement. And we aim to create both."
        />
      </Helmet>

      <header className={`${styles.aboutUsHeader} padding-block-end-6`}>
        <div className={styles.paddingMobile}></div>
        <div className={`container ${styles.aboutContainer}`}>
          <div className={styles.aboutContent}>
            <h2 className={`display-light`}>We’re CX-led</h2>
            <h1 className={`display-bold`}>software</h1>
            <h1 className={`display-bold padding-block-end-5`}>
              consultants<i className="dot"></i>
            </h1>
            <p
              className={`${styles.headerP} display greyed padding-block-end-4`}
            >
              That means we don’t just churn out nifty apps and pretty web
              pages. We create software that’s part of a larger solution – a
              solution that bridges the customer-business divide and delivers
              ROI. How?
            </p>

            <p className={`${styles.headerP} display greyed`}>
              It all starts with <strong>asking better questions.</strong>
            </p>
          </div>

          <div className={styles.aboutImage}>
            {!tablet && !mobile && (
              <object data={desktopHeader} type="image/svg+xml">
                <img src={desktopHeader} alt="Fallback" />
              </object>
            )}

            {tablet && !mobile && (
              <object data={tabletHeader} type="image/svg+xml">
                <img src={tabletHeader} alt="Fallback" />
              </object>
            )}

            {mobile && (
              <object data={mobileHeader} type="image/svg+xml">
                <img src={mobileHeader} alt="Fallback" />
              </object>
            )}
          </div>
        </div>
        <div className="text-center padding-block-start-4">
          <ScrollDownArrow label="Learn more about us" />
        </div>
      </header>

      <section
        className={`${styles.aboutUsSnippet} padding-block-6 margin-block-end-6`}
      >
        <div className="container">
          <div className={`${styles.aboutUsSnippetContent}`}>
            <h2 className="h1 greyed margin-block-end-4">
              We value <strong>more</strong> practice over best practice
            </h2>
            <p className="h4">
              It’s easy to be afraid of challenging “best practice” because,
              well - it’s the best, right? Perhaps. But at OnePointFour, we
              believe that nothing and no-one should be safe from scrutiny.
              Truly smart solutions don’t come from relying on what’s been done
              before. Neither does a culture of continual improvement.
              <br />
              <br />
              And we aim to create both.
            </p>
          </div>

          {!matches && (
            <div className={styles.aboutUsGame}>
              <span className={styles.paddle}></span>
              <span className={styles.paddle}></span>
              <span className={styles.ball}></span>
              <div className={styles.practice}>
                <span className={styles.label} aria-hidden="true">
                  Get some practice in now 🙂
                </span>
                <Button onClick={() => startGame()} theme="yellow">
                  Play Game
                </Button>
              </div>
            </div>
          )}
          {isOpen && !matches && (
            <div className={styles.pongGame}>
              <Pong />
            </div>
          )}
        </div>

        {!matches && (
          <div className={styles.gamePlayHowTo}>
            <div className={styles.group}>
              <kbd>W</kbd> or <kbd>↑</kbd> Move paddle up
            </div>
            <div className={styles.group}>
              <kbd>S</kbd> or <kbd>↓</kbd> Move paddle down
            </div>
            <div className={styles.group}>
              <kbd>Esc</kbd> To close game
            </div>
          </div>
        )}
      </section>

      <section className={`padding-block-start-6 padding-block-end-6`}>
        <h2>We have a great team</h2>
        <br />
        <PhotoGrid items={chunk(data, 3) as any} />
      </section>
    </div>
  );
}
